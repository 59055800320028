import App from 'AppInterface/App';
import { AppName, ArticleTypeMap, DBConfig, IsMinimalRhymeSite, isTestEnv, SHARE_ORIGIN } from 'config';
import { User } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import AnandDate from 'helpers/AnandDate';
import isMobileLib from 'ismobilejs';
import { ApplicationState, Article, ContentType, SandeshType, UserData } from 'types';

declare var webkit: any;

export function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

export function isMobile() {
	return isMobileLib().phone || isMobileLib().tablet || inIframe() || !isWebEnv();
}

export function isTablet() {
	return isMobileLib().tablet;
}

export function isAndroid() {
	return /Android/i.test(navigator.userAgent);
}

export function canContinouslyPlay() {
	return isAndroid() && (App.getBuildVersionCode() as unknown as number) >= 50;
}

export function isApple() {
	return /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
}

export function isSafari() {
	var issafari =
		navigator.vendor &&
		navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') === -1 &&
		navigator.userAgent.indexOf('FxiOS') === -1;

	issafari = issafari && /.*Version.*Safari.*/.test(navigator.userAgent);

	return issafari;
}

export function isFakeWebViewEnv() {
	return isWebViewEnv() || ((isMobileLib().phone || isMobileLib().tablet) && window.location.hostname === 'localhost') || isTestEnv;
}

export function isWebViewEnv() {
	return App.getAppEnv() !== 'Web';
}

export function isWebEnv() {
	return !((typeof webkit !== 'undefined' && webkit.messageHandlers && webkit.messageHandlers.AnandApp) || window.AnandApp);
	// return App.getAppEnv() === 'Web';
}

export function timeout(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function alignRhymes(el: HTMLElement) {
	if (!el) {
		return;
	}

	let rhymeContainers = el.getElementsByClassName('content-inline-rhymes');

	for (let i = 0; i < rhymeContainers.length; i++) {
		let rhymeContainer: HTMLElement = rhymeContainers[i] as HTMLElement;
		if (rhymeContainer.offsetParent === null) {
			// checks for visibility
			continue;
		}

		rhymeContainer.style.maxWidth = '';
		let rhymes = rhymeContainer.getElementsByTagName('rhyme-line');
		[].forEach.call(rhymes, (el) => {
			let rhyme = el as HTMLElement;
			rhyme.style.width = '';
			rhyme.classList.remove('rhyme-line-justified');
		});

		let minHeight = 100;
		for (let j = 0; j < rhymes.length; j++) {
			var height = rhymes[j].clientHeight;

			if (minHeight > height) {
				minHeight = height;
			}
		}

		for (let j = 0; j < rhymes.length; j++) {
			let fontSize = 1;
			while (fontSize >= 0.7 && rhymes[j].clientHeight / minHeight > 1.4) {
				fontSize -= 0.02;
				(rhymes[j] as HTMLElement).style.fontSize = fontSize + 'em';
			}
		}

		var maxLength = 0;
		for (let j = 0; j < rhymes.length; j++) {
			var length = rhymes[j].clientWidth;

			if (length > maxLength) {
				maxLength = length;
			}
		}

		// eslint-disable-next-line
		[].forEach.call(rhymes, (el) => {
			let rhyme = el as HTMLElement;
			rhyme.style.width = maxLength + 10 + 'px';
			rhyme.style.textAlign = '';
			rhyme.classList.add('rhyme-line-justified');
		});

		var rhymeAuthor = rhymeContainer.getElementsByTagName('rhyme-author')[0] as HTMLElement;
		if (rhymeAuthor) {
			rhymeAuthor.style.display = 'inline';
			rhymeAuthor.style.width = '';
			var rhymeAuthorWidth = rhymeAuthor.offsetWidth;
			rhymeContainer.style.maxWidth = maxLength + rhymeAuthorWidth + 'px';
			rhymeAuthor.style.maxWidth = maxLength + rhymeAuthorWidth + 'px';
			rhymeAuthor.style.display = 'block';
		}
	}
}

export function limitLines(el: HTMLElement, ogText: string, lines: number) {
	if (!el) {
		return;
	}

	let para = el.getElementsByTagName('p')[0];

	if (lines <= 0) {
		el.innerHTML = ogText;
		return;
	}

	para.style.width = 'max-content';
	let lineHeight = para.clientHeight;
	para.style.width = 'auto';

	let paraLines = Math.round(para.clientHeight / lineHeight);
	let text = para.innerText;

	while (paraLines > lines) {
		const words = text.split(' ');
		text = words.slice(0, words.length - 1).join(' ') + '....';

		para.innerText = text;
		paraLines = Math.round(para.clientHeight / lineHeight);
	}

	el.innerHTML = para.outerHTML;
	return;
}

export function getMediaBasePath(audioUri?, createdAt?) {
	return process.env.REACT_APP_CDN_HOST;
}

export function getMediaBasePathR2(audioUri?, createdAt?) {
	return process.env.REACT_APP_CDN_HOST_R2;
}

export function getContentStoragePath(type, key, object?) {
	switch (type) {
		case ContentType.Article:
			return encodeURI('appstatic/media/' + (object?.mediaUriR2 ?? object?.mediaUri ?? ''));
		case ContentType.Edition:
			return encodeURI('appstatic/publications/' + (key === 'cover' ? 'covers/' + (object?.coverpageUri ?? '') : 'files/' + (object?.fileUri ?? '')));
		case ContentType.WebApp:
			return 'appstatic/img/webapp/' + key;
		case ContentType.PKP:
			return 'appstatic/img/' + key;
		case ContentType.Calendar:
			return 'appstatic/calendar/' + key;
		case ContentType.Data:
			return 'appstatic/data/' + key;
		case ContentType.Files:
			return 'appstatic/files/' + key;
		case ContentType.User:
			return 'users/' + key + '/' + object?.name;
	}
	return '';
}

export function getContentStorageUrl(type, key, object?) {
	return (
		((type === ContentType.Article && object?.mediaUriR2) ||
		type === ContentType.Edition ||
		type === ContentType.Data ||
		type === ContentType.Calendar ||
		type === ContentType.WebApp ||
		type === ContentType.PKP
			? getMediaBasePathR2()
			: getMediaBasePath()) + getContentStoragePath(type, key, object)
	);
}

export function getWebAppAssetPath(key) {
	return getContentStorageUrl(ContentType.WebApp, key);
}

export const isAdmin = (user?: User, userData?: UserData) => {
	return userData && userData.roles ? userData.roles.indexOf('admin') > -1 || (IsMinimalRhymeSite && userData.roles.indexOf('rhymeadmin') > -1) : false;
};

export const isSuperAdmin = (user?: User, userData?: UserData) => {
	return userData && userData.roles ? userData.roles.indexOf('admin') > -1 : false;
};

export const isTest = (user?: User, userData?: UserData) => {
	return userData && userData.roles ? userData.roles.indexOf('test') > -1 : false;
};

export const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBuildVersion = () => {
	return parseInt(process.env.REACT_APP_BUILD_VER ?? '0');
};

const sessionId = Math.floor(Math.random() * 1000);
export const getSessionId = () => {
	return sessionId;
};

export const getPublishedOn = (dateStr: string, includeDate: boolean) => {
	var date = new AnandDate(dateStr);

	var result;
	if (includeDate) {
		result = date.format('Do MMMM, YYYY');
	} else {
		result = date.format('MMMM, YYYY');
	}

	return result;
};

export const getFormattedTimeFromSec = (seconds: number) => {
	seconds = Math.ceil(seconds);

	if (seconds <= 0) {
		return '00:00';
	}

	let timestr = '';
	let remainingSec = seconds;
	if (remainingSec >= 3600) {
		remainingSec = seconds % 3600;
		let hh = (seconds - remainingSec) / 3600;
		let hoursStr = hh.toString();
		if (hh < 10) {
			hoursStr = '0' + hoursStr;
		}
		timestr = hoursStr + ':';
		seconds = remainingSec;
	}

	remainingSec = seconds % 60;
	let mm = (seconds - remainingSec) / 60;

	let minuteStr = mm.toString();
	if (mm < 10) {
		minuteStr = '0' + minuteStr;
	}

	let secondsStr = remainingSec.toString();
	if (remainingSec < 10) {
		secondsStr = '0' + secondsStr;
	}

	timestr += minuteStr + ':' + secondsStr;

	return timestr;
};

export const getAudioFilenameFromSrc = (src: string) => {
	return src.substring(src.lastIndexOf('/') + 1);
};

export const shuffleArray = (array: any[]) => {
	var currentIndex = array.length,
		temporaryValue: any,
		randomIndex: number;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

export const updatePushSetting = (enable) => {
	if (enable) {
		App.subscribeToTopic('subscribed');
	} else {
		App.unsubscribeFromTopic('subscribed');
	}
};

export function isMessagingEnabled(state: ApplicationState) {
	let sandeshConfig = state.dataState.configs.byId[DBConfig.Sandesh]?.value;
	return (
		sandeshConfig?.messagingEnabled &&
		(isAdmin(state.userState.userStore.user, state.userState.userStore.userData) ||
			isTest(state.userState.userStore.user, state.userState.userStore.userData))
	);
}

export function isSSNRequestEnabled(state: ApplicationState) {
	let SSNConfig = state.dataState.configs.byId[DBConfig.SSN]?.value;
	let isMjBjUser = (state.userState.userStore.userData?.refUserIds?.length ?? 0) > 0;

	let admin = isAdmin(state.userState.userStore.user, state.userState.userStore.userData);
	let showAll = admin && state.userState.userStore.userData?.settings['admin.records'];

	let test = isTest(state.userState.userStore.user, state.userState.userStore.userData);

	return showAll || test || (isMjBjUser && (SSNConfig?.requestEnabled ?? false));
}

export function isAdminOrTest(state: ApplicationState) {
	let admin = isAdmin(state.userState.userStore.user, state.userState.userStore.userData);
	let test = isTest(state.userState.userStore.user, state.userState.userStore.userData);

	return admin || test;
}

export const filterSSNRecords = (state: ApplicationState, records) => {
	let SSNDates = state.dataState.configs.byId[DBConfig.SSN].value;
	let start: Timestamp = SSNDates.enableAt;
	let end: Timestamp = SSNDates.disableAt;

	let user = state.userState.userStore.user;
	if (!user) {
		return [];
	}

	return records.filter((record) => {
		// if (record.type !== SandeshType.SSN) {
		// 	return false;
		// }

		let result = record.receiverIds.indexOf(user?.phoneNumber ?? user?.uid) > -1;

		if (record.visibilityOverride !== undefined) {
			return result && record.visibilityOverride;
		}

		if (record.type === SandeshType.SSN) {
			let pub = record.publishTime?.seconds;
			if (pub && ((start && pub < start.seconds) || (end && pub > end.seconds))) {
				return false;
			}
		}

		return result;
	});
};

export const isSupportedCountry = (iso2: string) => {
	let supportedCountries = ['in', '91'];
	if (supportedCountries.indexOf(iso2) >= 0) {
		return true;
	}

	return false;
};

export const limitWords = (text: string, numWords: number) => {
	const words = text.split(' ');
	return words.slice(0, numWords).join(' ');
};

export const getShortUrl = async (record, url: string, force: boolean = false) => {
	let response = await fetch('https://ssdn.app/api/url', {
		method: 'POST',
		headers: {
			Accept: 'application.json',
			'Content-Type': 'application/json',
			token: '0.13447358407915444',
		},
		body: JSON.stringify({
			id: record.id,
			collection: 'articles',
			url: url,
			force: force,
			record: {
				author: record.author,
				mediaUri: record.mediaUri,
				mediaType: record.mediaType,
				mediaLength: record.mediaLength,
				title: record.title,
				articleType: record.articleType,
				lang: record.lang,
				group: record.group,
			},
		}),
	});

	return await response.text();
};

export const getArticleHref = (record) =>
	record?.mediaType === 'audio' || record?.mediaType === 'video'
		? '/' + record?.articleType + '/' + record?.id
		: '/' + record?.articleType + '/t/' + record?.id;

export const getArticleShareUrl = (record) => {
	let title = (record?.title.en || record?.title.hi) + ' - ' + ArticleTypeMap[record?.articleType || ''] + ', ' + AppName;
	let url = SHARE_ORIGIN + getArticleHref(record) + '&title=' + encodeURI(title);
	return url;
};
